import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = [];

export default function routerLocations(state = initialState, action) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return [...state, action.payload]
        case "DELETE_ROUTES":
            return []
        default:
            return state
    }
}