import React, { Component } from "react";
import {
  Link
} from 'react-router-dom';
// redux related dependencies
import { connect } from 'react-redux';
import {
  mainSignIn, signIn, signOut,
  languageChange, serviceAccessType, branchAccessType,
  multiBranchAccessType, accessTypeNone, tenantKey,
  refreshTokenKey, mainEndPointURL
} from "../Redux/Actions/index";
import { compose } from "redux";

// API
import { API_ENTRY, API_LOGIN_2, LOGINCONFIG, CONFIG } from "./api";
import HTTP from "../UTILs/HTTPAbstraction";

// CSS & Styling
import "./Signin.scss";
// other helping modules/dependencies
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn
} from 'mdbreact';

//Localization
import { withTranslation } from 'react-i18next';
// localization configuration instance
import i18n from '../i18n-config/i18n';
//Video
import bgVideo from '../Assets/login-bg.svg';
import UcellLogo from '../Assets/Images/My-Story-Metfone-logo.svg'
import loader from "../Assets/loader.gif";

var jwtDecode = require("jwt-decode");

// Assets
// const MODAL_OPEN_CLASS = "Sign-In";

class SignIn extends Component {
  constructor() {
    super();
    this.state = {
      client: new HTTP(),
      errorMessage: "",
      isDisabled: false,
      ShowLoginBox: false,
      showLoader: false,
      languageSelected: localStorage.getItem("mvp6_language"),

      langOptions: [
        {
          text: "en",
          value: "en",
          checked: localStorage.getItem("mvp6_language") === "en"
        },
        {
          text: "km",
          value: "km",
          checked: localStorage.getItem("mvp6_language") === "km"
        }
      ]
    }
    this.onChangeField = this.onChangeField.bind(this);
    this.submitSignIn = this.submitSignIn.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleLanguageSelection = this.handleLanguageSelection.bind(this);
    // this.handleChangeLanguage = this.handleChangeLanguage.bind(this)

  }

  componentDidMount() {
    if (localStorage.getItem('mvp6_language') === null) {
      this.handleLanguageSelection("km")
    }
  }

  onChangeField(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  submitSignIn() {
    this.setState({ showLoader: true });
    const [isValid, errorMessage] = this.validateFields();
    if (!isValid) {
      this.setState({
        errorMessage,
        showLoader: false
      })
      return;
    }

    const { client, username, password } = this.state;
    this.setState({
      isDisabled: true
    }, () => {
      // the token response will have a flag, this flag will be one of 3: 
      // 1- the token might inform us that we must go directly to the app, this way we will bypass the 
      // step where we must go to VAS dashboard, instead we will do another following API call.
      // for this we dispatch an action of 'signIn'
      // 2- the token might inform us that we will go to the VAS dashboard
      // for this we dispatch an action of 'mainSignIn'
      // 3- the token might inform us that we will go to Operator dashboard
      // for this we also dispathc an action of mainSignIn
      // for entry token there should be something called "Entry-Key" in the headers
      LOGINCONFIG.headers["Entry-Key"] = "sTNhCIgrkPORZPr+dXYKYHbG/cee41Yj7EL5ctw+xxUrw6GQiaKlZIbGROBKxGEZhWcUt5fCXk+0lEBMbn4QMQ==";

      client.post(API_ENTRY, { password, userName: username }, LOGINCONFIG, "").then(response => {
        if (response.status === 400) {

        }
        let decoded = jwtDecode(response.data.result.access_token)
        switch (this.state.languageSelected) {
          case "km":
            CONFIG.headers["Accept-Language"] = "km";
            break;
          default: CONFIG.headers["Accept-Language"] = "en-US";
        }

        // this.props.languageChange(language);
        if (decoded.user_access_level === "Service" && decoded.branches.length > 0) {
          let branch = decoded.branches[0];
          let service = branch.services[0];
          LOGINCONFIG.headers["Tenant-Key"] = service.tenant_key ? service.tenant_key : "";
          client.post(API_LOGIN_2, {}, LOGINCONFIG, response.data.result.access_token).then(innerResponse => {
            const { data } = innerResponse
            if (data.status === "success") {
              this.props.serviceAccessType();
              this.props.tenantKey(service.tenant_key);
              this.props.refreshTokenKey(service.refresh_token_key);
              this.props.mainEndPointURL(service.end_point);
              // signIn means we are singing in to the inner service
              this.props.signIn(data.result);
              if (service.role_name === "Editor") {
                this.props.history.push(`/${service.service_name}/videos`);
              } else {
                this.props.history.push(`/${service.service_name}`);
              }
              //let url = "http://localhost:1232/vrbt/dashboard";
              // let url = "http://vrbt.my-story.uz/vrbt/dashboard";
              // let uagent = navigator.userAgent.toLowerCase();
              // if (/safari/.test(uagent) && !/chrome/.test(uagent)) {
              //     window.location.href = url;
              // } else {
              //     window.location.replace(url)
              // }

            } else {
              this.setState({
                isDisabled: false, showLoader: false
              })
            }
          }).catch(exception => {
            if (exception.response && exception.response.status === 401) {
              this.setState({ errorMessage: "Invalid Credentials", isDisabled: false, showLoader: false });
            } else if (exception.response && (exception.response.status === 400 || exception.response.status === 404)) {
              this.setState({ invalid: "Invalid Credentials", isDisabled: false, showLoader: false });
            } else {
              this.setState({
                isDisabled: false,
                errorMessage: "Connection Error",
                showLoader: false
              })
            }
          })
        } else if (decoded.user_access_level === "Branch") {
          this.props.branchAccessType();
          this.props.mainSignIn(response.data.result);
          this.props.history.push("/OperatorDashboard");
        } else if (decoded.user_access_level === "MultiBranch") {
          this.props.multiBranchAccessType();
          // mainSignIn means we are singing in to the main outer dashboard
          this.props.mainSignIn(response.data.result);
          this.setState({
            isDisabled: false
          })
          this.props.history.push("/headquarter-dashboard");
        } else {

        }
      }).catch(exception => {
        if (exception.response && exception.response.status === 401) {
          this.setState({ errorMessage: "Invalid Credentials", isDisabled: false, showLoader: false });
        } else if (exception.response && (exception.response.status === 400 || exception.response.status === 404)) {
          this.setState({ errorMessage: "Invalid Credentials", isDisabled: false, showLoader: false });
        } else {
          this.setState({
            isDisabled: false,
            showLoader: false,
            errorMessage: "Connection Error"
          })
        }
      })
    })
  }

  validateFields() {
    const { username, password } = this.state;

    let errorMessage = "";
    let isValid = true;
    if (!username || username.length < 8) {
      errorMessage = "Username must be 8 characters at least";
      isValid = false;
      return [isValid, errorMessage]
    }

    if (!password || password.length < 8) {
      errorMessage = "Password must be 8 characters at least"
      isValid = false;
      return [isValid, errorMessage]
    }

    return [isValid, errorMessage]
  }

  showLoginFields() {
    this.setState({
      ShowLoginBox: true
    })
  }

  handleLanguageSelection(lang) {

    localStorage.setItem('mvp6_language', lang);
    i18n.changeLanguage(lang);
    this.props.languageChange(lang);

    this.setState((prevState) => ({
      languageSelected: !prevState.languageSelected
    }))
  }

  render() {
    const { t, token, loginToken } = this.props;
    let isLoggedIn = token !== "" || loginToken !== [] && loginToken !== "" && loginToken !== undefined || false;
    return (
      <>

        {/* <video className='videoTag' autoPlay loop muted>
          <source src={bgVideo} type='video/mp4' />
        </video> */}

        <img src={bgVideo} alt="Ucel-bg" className='videoTag' />


        {/* {this.state.ShowLoginBox && (
          <video className='videoTag' paused>
            <source src={bgVideo} type='video/mp4' />
          </video>
        )} */}

        <MDBRow className="top-mainpage mx-0">
          <MDBCol md="6"></MDBCol>
          <MDBCol md="6" className="mt-5">
            {isLoggedIn ?
              <MDBBtn className="sign-in" onClick={this.props.signOut}>{t("Log Out")}</MDBBtn>
              :
              <MDBBtn className="sign-in" onClick={() => this.showLoginFields()}>{t("Log in")}</MDBBtn>
            }
          </MDBCol>
          <MDBCol md="12" className="mt-2 main-Ucell-logo">
            <img src={UcellLogo} alt="Ucell-Logo" />
          </MDBCol>
        </MDBRow>

        {!this.state.ShowLoginBox && (
          <MDBContainer className="position-absolute top-50percent left-50percent translate-50percent">
            <MDBRow center={true}>
              <MDBCol md="6" className="sign-in-content">
                <h3 className="">1998 - {new Date().getFullYear()}</h3>
                <p>
                  Monty Mobile VAS Platform
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}

        {this.state.ShowLoginBox && (
          <MDBContainer className="position-absolute top-50percent left-50percent translate-50percent signin-form">
            <MDBRow center={true}>
              <MDBCol md="6">
                <MDBCard className="login-card">
                  <MDBCardBody className="mx-4">
                    <div>
                      <h3 className="dark-grey-text mb-5">
                        {t("Sign in")}
                      </h3>
                      {/* <MDBSwitch
                        checked={this.state.languageSwitch_EN}
                        className={"reset-float text-align-right lang-switch"}
                        labelLeft="En"
                        labelRight="Vi"
                        onChange={this.handleLanguageSwitch} /> */}
                      {/* <span className="language-dropdown">
                        <MDBIcon className="langIcon hide-on-mobile" icon="globe" />
                        <MDBSelect
                          getValue={e => this.handleLanguageSelection(e)}
                          className="langDD"
                          options={this.state.langOptions}
                          selected={i18n.language}
                          label=""
                        />
                      </span> */}
                    </div>
                    <input
                      className="mdb-alternative"
                      label=""
                      placeholder={t("Username")}
                      name="username"
                      group
                      error="wrong"
                      success="right"
                      onChange={this.onChangeField}
                    />
                    <input
                      className="mdb-alternative"
                      label=""
                      placeholder={t("Password")}
                      name="password"
                      group
                      type="password"
                      validate
                      containerClass="mb-0"
                      onChange={this.onChangeField}
                    />

                    <p className="font-small d-flex pb-3 forget">
                      <Link className="ml-1" to="/forgot-password">
                        {t("Forgot Password?")}
                      </Link>
                    </p>

                    <div className="text-center mb-3">
                      <button
                        type="button"
                        className="brown-sign-in"
                        onClick={this.submitSignIn}
                        disabled={this.state.isDisabled}
                      >

                        <span className={this.state.showLoader ? 'hide' : 'show'} >{t("Sign in")}</span>
                        <img className={this.state.showLoader ? 'show' : 'hide'} src={loader} alt="loader" width="30px" />

                      </button>
                    </div>
                  </MDBCardBody>
                  <MDBRow center={true}>
                    <p className="height-12px color-ff3547">
                      {this.state.errorMessage}
                    </p>
                  </MDBRow>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}

        {/* <MDBRow className="footer-mainpage mx-0">
          <MDBContainer className="position-absolute top-50percent left-50percent translate-50percent">
            <MDBRow center={true}>
              <MDBCol md="12">
                <p>Powered by</p>
                <img className="logo-monty-footer" src={montyLogo} alt="Monty_logo" />
              </MDBCol>
              <MDBCol md="12">
                <ul className="footer-main-menu">
                  {/* <li><a href={"#"}>Privacy Policy</a></li>
                  <li><a href={"#"}>Terms &amp; Conditions</a></li> *
                  <li><p>Copyright © 2020 Monty Mobile. All rights reserved.</p></li>
                </ul>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow> */}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  token: state.authentication ? state.authentication.token : "",
  token_expiry: state.authentication ? state.authentication.expiry : "",
  errorMessage: "",
  loginToken: state.authentication ? state.authentication.loginToken : []
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  mainSignIn: (token) => dispatch(mainSignIn(token)),
  signIn: (token) => dispatch(signIn(token)),
  signOut: () => dispatch(signOut()),
  languageChange: (lang) => dispatch(languageChange(lang)),
  serviceAccessType: () => dispatch(serviceAccessType()),
  branchAccessType: () => dispatch(branchAccessType()),
  multiBranchAccessType: () => dispatch(multiBranchAccessType()),
  accessTypeNone: () => dispatch(accessTypeNone()),
  tenantKey: (key) => dispatch(tenantKey(key)),
  refreshTokenKey: (key) => dispatch(refreshTokenKey(key)),
  mainEndPointURL: (url) => dispatch(mainEndPointURL(url))
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withTranslation("translations")(SignIn));