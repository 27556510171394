import React, { Component} from "react";

class Loader extends Component {
    constructor(props){
        super(props);
        this.state = {
            logoComponent: props.logo
        }
    }

    render() {
        const { logoComponent } = this.state;
        return (
            <React.Fragment>
                <img src={logoComponent} alt="monty_logo" class={"main-logo-loader"}/>
            </React.Fragment>
        )
    }
}

export default Loader