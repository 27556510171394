import React from "react";
import Routes from "./CombinedRoutes";

function App() {
  return (
      <Routes />
  );
}

export default App;
