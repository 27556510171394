// here the API urls will be located
export const API_URL_IDENTITY = "http://cloudvas-identity.montymobile.com/api/v1"
export const API_URL_IDENTITY_V2 = "http://cloudvas-identity.montymobile.com/api/v2"

// export const API_URL_IDENTITY = "http://34.253.54.11:9000/api/v1"
// export const API_URL_IDENTITY_V2 = "http://34.253.54.11:9000/api/v2"

export const VRBT_ADMIN_ENDPOINT = "http://adm-metfone.my-stories.net"
export const VRBT_OPERATOR_ENDPOINT = "http://op.metfone.my-stories.net"
export const VRBT_PROVIDER_ENDPOINT = "http://cp.metfone.my-stories.net"

// export const VRBT_ADMIN_ENDPOINT = "http://63.32.209.135:6630"
// export const VRBT_OPERATOR_ENDPOINT = "http://63.32.209.135:6632"
// export const VRBT_PROVIDER_ENDPOINT = "http://63.32.209.135:6631"

export const VRBT_PATH_NAME = "Metfone-VRBT"
export const STORYRBT_PATH_NAME = "Metfone-VRBT"

export const VRBT_TOKEN = "PFO8IgQY2Eh8W3Tz3XGqSJzFY5EXoQww"

export const token = "bearer "

export const LOGINCONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Tenant-Key": "d5019249-fbe6-4b0f-9d5b-c54bf1c15ba1",
    "Content-Type": "application/json"
  }
}
export const CHANGEPASSCONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  //  "Tenant-Key": "cb5c3935-7b69-449f-88a0-26d846f51b9f",
    Authorization: token,
    "Content-Type": "application/json"
  }
}
export const CONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    Authorization: token,
    "Content-Type": "application/json"
  }
}

export const CONFIGFORMDATA = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    Authorization: token,
    "Content-Type": "multipart/form-data"
  }
}

export const CONFIGNOAHTH = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Content-Type": "application/json"
  }
}

// The Api route that responds with the access rights for each page
export const ACCESS_RIGHTS = API_URL_IDENTITY + "/Pages/Access"

 // lookups
export const AUTHORITIES_LOOKUP = VRBT_ADMIN_ENDPOINT + "/authorities"

export const API_ALL_USER = API_URL_IDENTITY + "/Users"

export const GET_DASHBOARD_DATA= VRBT_ADMIN_ENDPOINT + "/report/dashboard"

export const SUBSCRIPTION_MANAGEMENT_REPORT= VRBT_ADMIN_ENDPOINT + "/report/subscribersManagement?pageOffset=0&pageSize=100"
export const DEACTIVATE_SUBSCRIBER= VRBT_ADMIN_ENDPOINT + "/report/deactivate"

export const TRANSACTION_ANALYSIS_REPORT= VRBT_ADMIN_ENDPOINT + "/report/transactionAnalysis?pageOffset=0&size=100"
export const TRANSACTION_DETAILS= VRBT_ADMIN_ENDPOINT + "/report/transactionAnalysis"

export const SUBSCRIPTION_FEES_REPORT= VRBT_ADMIN_ENDPOINT + "/report/subscriptionFees?pageOffset=0&size=100"
export const INTEREST_RATE_REPORT= VRBT_ADMIN_ENDPOINT + "/report/interestRate?pageOffset=0&size=100"
export const BED_DEBT_REPORT= VRBT_ADMIN_ENDPOINT + "/report/badDebt?pageOffset=0&size=100"
export const FOREX_REPORT= VRBT_ADMIN_ENDPOINT + "/report/forexExchange?pageOffset=0&size=100"
export const RECONCILIATION_REPORT= VRBT_ADMIN_ENDPOINT + "/report/reconciliationReport"

export const UserLogs_ADMIN = VRBT_ADMIN_ENDPOINT + '/auth/getuserlogs'

export const GET_ALLCATEGORIES_ADMIN = VRBT_ADMIN_ENDPOINT + '/category/getallcategories'
export const GET_VIDEOSBYCATEGORY_ADMIN = VRBT_ADMIN_ENDPOINT + '/category/getVideosByCategory'
export const SET_CATEGORY_PRIORITY = `${VRBT_ADMIN_ENDPOINT}/category/setpriority`;

export const ADD_CATEGORY_ADMIN = VRBT_ADMIN_ENDPOINT + '/category/Create'

export const UPDATE_CATEGORY_ADMIN = VRBT_ADMIN_ENDPOINT + '/category/Update'

export const DELETE_CATEGORY_ADMIN = VRBT_ADMIN_ENDPOINT + '/category/Delete'

export const GET_CONTENTPROVIDERSUSERS_ADMIN = VRBT_ADMIN_ENDPOINT + '/provider/View'
export const ADD_CONTENTPROVIDERSUSER_ADMIN = VRBT_ADMIN_ENDPOINT + '/provider/Create'
export const UPDATE_CONTENTPROVIDERSUSER_ADMIN = VRBT_ADMIN_ENDPOINT + '/provider/Update'

export const GET_CONTENTPROVIDERSVIDEORS_ADMIN = VRBT_ADMIN_ENDPOINT + '/provider/GetProviderVideos'

export const GET_SUBSCRIBERS_ADMIN = VRBT_ADMIN_ENDPOINT + '/subscriber/getsubscribers'
export const GET_SUBSCRIBERSVIDEOS_ADMIN = VRBT_ADMIN_ENDPOINT + '/Subscriber/GetSubscriberVideos'

export const GET_VRBT_ADMIN = VRBT_ADMIN_ENDPOINT + '/Video/GetVideoSetRBT'

export const GET_VRBT_SUB_HISTORY = VRBT_ADMIN_ENDPOINT + '/report/subscriptionHistory'

export const GET_DEFAULTRBT_ADMIN = VRBT_ADMIN_ENDPOINT + '/DefaultRbt/View'

export const ASSIGN_DEFAULTRBT_ADMIN = VRBT_ADMIN_ENDPOINT + '/DefaultRbt/Create'

export const GET_TOTALSUBSCRIPTION_ADMIN = VRBT_ADMIN_ENDPOINT + '/Report/GetTotalSubscription'
export const GET_TOTALUNSUBSCRIPTION_ADMIN= VRBT_ADMIN_ENDPOINT + '/Report/GetTotalUnsubscription'
export const GET_VIDEOSETRBT_ADMIN= VRBT_ADMIN_ENDPOINT + '/Report/GetVideoSetRBT'
export const GET_VIEWEDVIDEOSONCALL_ADMIN= VRBT_ADMIN_ENDPOINT + '/Report/GetViewedVideosOnCall'

export const GETACTIVEUSERS_DASHBOARD_ADMIN = VRBT_ADMIN_ENDPOINT + '/dashboard/getActiveUsers'
export const GET_TOPPLAYEDVIDEOS_DASHBOARD_ADMIN = VRBT_ADMIN_ENDPOINT + '/dashboard/getTopPlayedVideos'
export const GETORIGINATINGCOUNTRIES_DASHBOARD_ADMIN = VRBT_ADMIN_ENDPOINT + '/dashboard/GetOriginatingCountries'
export const GETDESTINATIONCOUNTRIES_DASHBOARD_ADMIN = VRBT_ADMIN_ENDPOINT + '/dashboard/GetDestinationCountries'

export const GETSUBSCRIPTIONSUNSUBSCRIPTIONSPERMONTH_DASHBOARD_OPERATOR = VRBT_OPERATOR_ENDPOINT + '/dashboard/GetSubscriptionsUnsubscriptionsPerMonth'
export const GETSUBSCRIPTIONSUNSUBSCRIPTIONSMONTHLY_DASHBOARD_OPERATOR = VRBT_OPERATOR_ENDPOINT + '/Dashboard/GetSubscriptionsUnsubscriptionsMonthly'

export const GETVIDEOSPERPERCONTENTPROVIDERWEEKLY_DASHBOARD_PROVIDER = VRBT_PROVIDER_ENDPOINT + '/Dashboard/GetVideosPerContentProviderWeekly'

export const GET_SUBSCRIBERS_OPERATOR = VRBT_OPERATOR_ENDPOINT + '/subscriber/getsubscribers'

export const GET_CONTENTPROVIDERSVIDEORS_PROVIDER = VRBT_PROVIDER_ENDPOINT + '/Video/GetContentProviderVideos'

export const UPLOAD_VIDEO_PROVIDER = VRBT_PROVIDER_ENDPOINT + '/Video/Upload'

export const GET_ALLCATEGORIES_PROVIDER = VRBT_PROVIDER_ENDPOINT + '/video/getallcategories'

export const CHANGE_PASSWORD_IDENDITY = API_URL_IDENTITY_V2  + '/Account/ChangePassword'

export const GET_PROFILE_IDENDITY = API_URL_IDENTITY  + '/Account'

export const UPDATE_PROFILE_IDENDITY = API_URL_IDENTITY  + '/Account'

// New API

export const ADMIN_GET_REPORT = VRBT_ADMIN_ENDPOINT + "/Report/getReport"

export const ADMIN_GET_REPORT_SMS_LOGS = VRBT_ADMIN_ENDPOINT + "/Report/charging?msisdn="

export const ADMIN_GET_REPORT_SUBS = VRBT_ADMIN_ENDPOINT + "/Report/getSubscriber?msisdn="

export const ADMIN_POST_UNSUB = VRBT_ADMIN_ENDPOINT + "/Report/unsubscribe"


export const ADMIN_DETAILS_REPORT = VRBT_ADMIN_ENDPOINT + "/Report/detailedReportPerDay"
export const ADMIN_SUMMARY_REPORT =  VRBT_ADMIN_ENDPOINT + "/Report/summaryReportPerDay"
export const ADMIN_CUSTOMER_CARE_REPORT = VRBT_ADMIN_ENDPOINT + "/Report/customerCare"


// New Dashboard
export const GET_SUB_REVNUE = VRBT_ADMIN_ENDPOINT + "/dashboard/subRevenuePerDay"
export const GET_FAILED_SUB = VRBT_ADMIN_ENDPOINT + "/dashboard/failedSubscription"

export const GET_SUB_UNSUB = VRBT_ADMIN_ENDPOINT + "/dashboard/subUnsubPerChannel"
export const GET_CATEGORIES_DOWNLOADS = VRBT_ADMIN_ENDPOINT + "/dashboard/getCategoriesDownloads"

export const GET_DASHBOARD_SUMMARY = VRBT_ADMIN_ENDPOINT + "/dashboard/summary"

export const DELETE_VIDEO = VRBT_ADMIN_ENDPOINT + "/provider/video/Delete?videoid="

export const ALL_USER_API = VRBT_ADMIN_ENDPOINT + '/Auth/Create'