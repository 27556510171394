import { REFRESH_TOKEN } from "../../Authentication/api";
import axios from "axios";
import dictionary from "./directionsDictionary";
import { LOCATION_CHANGE } from 'connected-react-router';
var jwtDecode = require("jwt-decode");
let refresh_Timer = null;
/*
  This is a description of the association between signIn (and _signIn) action, refresher_Initialization action and refresh function
  A- First signIn action is dispatched, of course it is only dispatched on the success of the API call that was made in the Container that made the dispatch request.
  B- This action then dispatches 2 actions: 1- _signIn action 2- refresher_Initialization action.
  C- The _signIn simply has stores the 'token' and 'expiry' in the state.
  The refresher_Initialization depends on _signIn action (and later refreshSuccess), since dispatch itself is synchronous then refresher_Initialization is excuted after _signIn.
  D- What refresher_Initialization does is extract the token from the state, check its expiry time then set up a timer to call refresh function 
  one minute before expiring.
  refresh function will make an axios call to get the new token, when it gets it successfully it makes two dispatches, one dispatch call to
  refreshSuccess which stores the new token, the following dispatch is to refresher_Initialization itself. This means step D is repeated again in recursive manner!
*/

export const signIn = (token) => {
  return (dispatch) => {
    dispatch(_signIn(token));
    dispatch(refresher_Initialization());
  }
}

export const _signIn = (token) => ({
  type: 'SIGN_IN',
  token: token
})

export const signOut = () => {
  return (dispatch) => {
    dispatch(_signOut());
    dispatch(accessTypeNone());
    dispatch(deleteRouteMemory());
    dispatch(deleteKeys());
    dispatch(deleteAPIEndPoints());
  }
}

export const _signOut = () => {
  // we must clear the timeout of refresh because it might have been declared, which means  
  // it will refresh token even after being signed out, this leads to being signed in AGAIN!!
  clearTimeout(refresh_Timer);
  return {
    type: 'SIGN_OUT'
  }
}

// notifies me that the user is not logged in
export const nonRegistered = () => ({
  type: 'NON_REGISTERED_USER'
})

export const refreshSuccess = token => ({
  type: 'REFRESH_TOKEN',
  token: token
})

export const languageChange = lang => {
  return (dispatch) => {
    dispatch(_languageChange(lang));
    dispatch(dltr_rtl_dir(dictionary[lang]));
  }
}

export const mainSignIn = (token) => ({
  type: 'MAIN_SIGN_IN',
  token: token
})

export const _languageChange = lang => ({
  type: 'LANGUAGE_CHANGE',
  lang: lang
})

export const dltr_rtl_dir = (dir) => ({
  type: "DIRECTION_CHANGE",
  dir: dir
})

export const serviceAccessType = () => ({
  type: "SERVICE"
})

export const branchAccessType = () => ({
  type: "BRANCH"
})

export const multiBranchAccessType = () => ({
  type: "MULTIBRANCH"
})

export const accessTypeNone = () => ({
  type: "NO_ACCESS_TYPE"
})

export const routeChange = (path) => ({
  type: LOCATION_CHANGE,
  payload: path
})

export const deleteRouteMemory = () => ({
  type: "DELETE_ROUTES"
})

export const tenantKey = (tenant_key) => ({
  type: "TENANT_KEY",
  tenant_key
})

export const refreshTokenKey = (refresh_token_key) => ({
  type: "REFRESH_TOKEN_KEY",
  refresh_token_key
})

export const deleteKeys = () => ({
  type: "DELETE_KEYS"
})

export const mainEndPointURL = (endPointURL) => ({
  type: "MAIN_ENDPOINT_URL",
  endPointURL
})

export const deleteAPIEndPoints = () => ({
  type: "DELETE_API_ENDPOINTS"
})

export const refresher_Initialization = () => {
  return (dispatch, getState) => {
    // if there is a token in storage then we will have 3 cases to look for: 1- token expired --> sign out immediately 2- token has less than 60 seconds of lifetime --> refresh token immediately
    // 3- token has more than 60 seconds --> set up timer for refresh
    if (getState().authentication && getState().authentication.token) {
      let decoded = jwtDecode(getState().authentication.token);
      // var err = new Error();
      // console.log("err.stack refresher_Initialization",err.stack);
      // console.trace()
      let tokenExp = decoded.exp;
      let judgement_Date = new Date(0); // The 0 there is the key, which sets the date to the epoch
      judgement_Date.setUTCSeconds(tokenExp)

      if (judgement_Date.getTime() - (new Date()).getTime() < 0) {
        // if the token time is prior to current time then dispatch signOut because token expired!
        dispatch(signOut())
      } else if (judgement_Date.getTime() - (new Date()).getTime() < 60000
        && judgement_Date.getTime() - (new Date()).getTime() > 0) {
        // if the time remaining for the token is less than 60 seconds then we want to immediately execute the Refresh Token funciton
        // make an API call immediately
        refresh(getState().authentication.token, dispatch)
      } else {
        // if we have more than 60 seconds left for the token then we create a setTimeOut that will wait until the Token has 60 seconds left in its expiry
        let executionTime = (judgement_Date.getTime() - 60000) - (new Date()).getTime();
        if (executionTime > 2147483647) {
          // this if statement is checking if executionTime is bigger than 2147483647 because if it is then setTimeOut will break
          // it will break because setTimeout is built to store a 32 bit int. Anything bigger than that will make the setTimeOut
          // call instantly
          executionTime = 2147483647;
        }
        refresh_Timer = setTimeout(() => refresh(getState().authentication.token, dispatch), executionTime)
      }
    } else {
      // there is no token which means that this is a fresh user
      dispatch(nonRegistered())
      dispatch(deleteRouteMemory())
    }

  }
}

function refresh(token, dispatch) {
  const CONFIGREFRESH = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      Authorization: "bearer " + token,
      "Content-Type": "application/json",
      "Refresh-Token-Key": "2gee0zAnlwgrd6HEoBbjv9Hu6HJYxEM9L7Sa1E6jzW0cUAFpj3/lBEIz1nO1MkfG5iwbDNsfyhjK0S1tUAtkVQ=="
      //"eMLC+89csf1edR63QfxAdYj7tLprdFXJ1eiBJN8MQ43WB3CSjhtj9KIIEFYVI4HxzYhPKwAIN8DVvgo5CeRFoQ=="
    }
  }

  try {
    axios.post(REFRESH_TOKEN, {}, CONFIGREFRESH).then(res => {
      // inside here we set the new token to redux storage
      // later we make another call to refresher_Initialization which will handle the refreshment of token
      if (res.data && res.data.result && res.data.result.access_token) {
        dispatch(refreshSuccess(res.data.result));
        dispatch(refresher_Initialization());
      } else {
        dispatch(signOut());
      }
    }).catch(error => {
      dispatch(signOut());
    })
  } catch (error) {
    // if we couldn't succeed in getting new token then we will sign out
    dispatch(signOut());
  }
}

export const VisibilityFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_COMPLETED: 'SHOW_COMPLETED',
  SHOW_ACTIVE: 'SHOW_ACTIVE'
}