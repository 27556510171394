const initialState = {
    user_access_level: "None"
}


/*
    1- if the user is of type Service then inside the portal/app he will not see the buttons "Headquarter Dashboard" or "Operator Dashboard"
    2- if the user is of type Branch then inside of the portal/app he will NOT see the button "Headquarter Dashboard", he will see only "Operator Dashboard"
    3- if the user us of type MultiBranch then inside of the portal/app he will NOT see the button "Operator Dashboard", he will see only "Headquarter Dashboard"
*/
const accessLevelReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SERVICE":
            return {
                ...state,
                user_access_level: "Service"
            }
        case "BRANCH":
            return {
                ...state,
                user_access_level: "Branch"
            }
        case "MULTIBRANCH":
            return {
                ...state,
                user_access_level: "MultiBranch"
            }
        case "NO_ACCESS_TYPE":
            return {
                ...state,
                user_access_level: "None"
            }
        default:
            return state
    }
}

export default accessLevelReducer;