import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import Joi from "joi";
import HTTP from "../UTILs/HTTPAbstraction"
import * as actions from "../Redux/Actions/index";
import "./Signin.scss";
import { FORGET_PASSWORD, LOGINCONFIG } from "./api";
import UcellLogo from '../Assets/Images/My-Story-Metfone-logo.svg'
import { withTranslation } from 'react-i18next';

const MODAL_OPEN_CLASS = "login"

class Forget extends Component {
  constructor() {
    super();
    this.state = {
      client: new HTTP(),
      username: "",
      data: { username: "" },
      errors: {},
      forgetMessage: "",
      usernameSubmitted: false
    }

    this.schema = {
      username: Joi.string()
        .required()
        .min(5)
        .max(50)
    };

    this.goHome = this.goHome.bind(this);
  }
  componentDidMount() {
    document.body.classList.add(MODAL_OPEN_CLASS);
    if (this.props.mainToken) {
      this.props.history.push("/headquarter-dashboard");
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(MODAL_OPEN_CLASS);
  }

  handleChange = e => {
    const data = { ...this.state.data };
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ data });
  };

  validate = () => {
    const errors = {};
    const result = Joi.validate(this.state.data, this.schema, {
      abortEarly: false
    });
    if (!result.error) return null;
    for (let items of result.error.details)
      errors[items.path[0]] = items.message;

    return errors;
  };

  onSubmit = formProps => {
    const { client } = this.state;

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    let dataToSend = { userName: formProps.username }
    if (!errors) {
      try {

        client.post(FORGET_PASSWORD, dataToSend, LOGINCONFIG, "")
          .then(data => {
            if (data.data.status === "success") {
              this.setState({ forgetMessage: "An email is being sent to your inbox. Please follow the steps in the email.", usernameSubmitted: true });

            } else {
              this.setState({ forgetMessage: "Invalid Username. Please contact your administrator.", usernameSubmitted: false });
            }
          })
          .catch(error => {
            this.setState({ forgetMessage: "Invalid Username. Please contact your administrator.", usernameSubmitted: false });
          });

      } catch (ex) {
        if (ex.response && ex.response.status === 400 && ex.response.status === 404) {
          const errors = { ...this.state.errors };
          errors.username = ex.response.data;
          this.setState({ errors, loadingButton: false });
          this.setState({ forgetMessage: "Something wrong happened, kindly try again", usernameSubmitted: false });
        } else {
          this.setState({ loadingButton: false });
          this.setState({ forgetMessage: "Something wrong happened, kindly try again", usernameSubmitted: false });
        }
      }
    }
  }

  goHome() {
    this.props.history.push("/");
  }

  render() {
    const { handleSubmit } = this.props;
    const { errors, usernameSubmitted, forgetMessage } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="login-page">
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <img
                src={UcellLogo}
                width="150px"
                alt="Logo"
                className="user-avatar"
              />
              <h1>{t("Forgotten your password?")}</h1>
              <p>
                {t("Forgotten your password? Enter your username below, and we’ll email instructions for setting a new one.")}
              </p>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="form-content">
                  {forgetMessage && (<div className="form-group invalid-msg">
                    {forgetMessage}
                  </div>)}
                  <div className="form-group">
                    <Field
                      className={
                        errors["username"]
                          ? "is-invalid form-control input-underline input-lg"
                          : "form-control input-underline input-lg"
                      }
                      placeholder={t("Username*")}
                      name="username"
                      type="text"
                      component="input"
                      onChange={this.handleChange}
                      maxLength="50"
                    />
                    <div className="invalid-feedback">
                      {t("Username must be at least 5 characters long")}
                    </div>
                  </div>
                  {
                    usernameSubmitted ?
                      <div className="form-group login-but">
                        <button className="btn rounded-btn" onClick={this.goHome}>Home</button>
                      </div>
                      :
                      <div className="form-group login-but">
                        <button className="btn rounded-btn">{t("Send")}</button>
                      </div>
                  }
                </div>
              </form>

            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  token: state.authentication ? state.authentication.token : "",
  token_expiry: state.authentication ? state.authentication.expiry : "",
  loginToken: state.authentication ? state.authentication.loginToken : [],
  mainToken: state.authentication ? state.authentication.mainToken : ""
})

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({ form: "forget-pass" })
)(withTranslation("translations")(Forget))