import React, { Component } from "react";
import PropTypes from "prop-types"
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import Joi from "joi";
import * as actions from "../Redux/Actions/index";
import axios from "axios";
import logo from '../Assets/Images/My-Story-Metfone-logo.svg'
import "./Signin.scss";
import { RESET_PASSWORD } from "./api";
import { withTranslation } from "react-i18next"

const MODAL_OPEN_CLASS = "login";

class ResetPassword extends Component {
  componentDidMount() {
    document.body.classList.add(MODAL_OPEN_CLASS)
  }

  componentWillUnmount() {
    document.body.classList.remove(MODAL_OPEN_CLASS);
  }

  static propTypes = {
    password: PropTypes.func
  }

  state = {
    userName: "",
    password: "",
    data: { userName: "", password: "" },
    errors: {},
    message: "",
    emptyPassword: false,
    showConfirmPassError: false
  }

  schema = {
    userName: Joi.string()
      .required()
      .min(5)
      .max(50),
    password: Joi.string()
      .regex(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[\!\@\#\$\%]).{6,}$/)
      .required()
      .min(8)
      .max(16),
    ConfirmPassword: Joi.any().valid(Joi.ref('password')).required()//.options({ language: { any: { allowOnly: 'must match password' } } })
  }

  isPassword(data) {
    var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[\!\@\#\$\%]).{6,}$/;
    var password = data;
    let isPassword;
    isPassword = regex.test(password);
    return isPassword;
  }

  handleChange = e => {
    // const data = { ...this.state.data };
    // data[e.currentTarget.name] = e.currentTarget.value;
    // this.setState({ data });

    this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    })
  };

  validate = () => {

    if (this.state.password === "") {
      this.setState({ emptyPassword: false })
    } else {
      this.setState({ emptyPassword: true })
    }

    const errors = {};
    let data = {}
    data = {
      userName: this.state.userName,
      password: this.state.password,
      ConfirmPassword: this.state.ConfirmPassword
    }
    //  const { userName, password ,ConfirmPassword} = this.state; 
    const result = Joi.validate(data, this.schema, {
      abortEarly: false
    });
    if (!result.error) return null;
    for (let items of result.error.details)
      errors[items.path[0]] = items.message;

    return errors;
  };

  onSubmit = event => {

    const errors = {};

    if (!this.state.userName)
      errors.userName = true;
    if (!this.state.password)
      errors.password = true;
    if (!this.state.ConfirmPassword || this.state.password !== this.state.ConfirmPassword)
      errors.ConfirmPassword = true;

    this.setState({ errors });
    if (errors.userName || errors.password || errors.ConfirmPassword)
      return;

    var parsedUrl = new URL(window.location.href)
    const token = parsedUrl.search
    var resetToken = token.substr(7);
    let data = {}
    data = {
      userName: this.state.userName,
      password: this.state.password,
      ConfirmPassword: this.state.ConfirmPassword
    }
    var data_to_send = data;

    let headerConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept, Authorization",
        "Tenant-Key": "7760f3f6-1062-488b-9a82-645f03c65e44",
        "Content-Type": "application/json",
        "Reset-Token": resetToken
      }
    }

    data_to_send.newPassword = this.state.password;

    // if (this.state.ConfirmPassword) {
    //   if (this.state.password !== this.state.ConfirmPassword) {
    //     this.setState({ showConfirmPassError: true })
    //   }
    //   else {
    //     this.setState({ showConfirmPassError: false })
    //   }
    // }
    // if (this.state.password) {
    //   if (!this.isPassword(this.state.password)) {
    //     this.setState({ password: this.state.password, emptyPassword: true });
    //     return;
    //   }
    //   else {
    //     this.setState({ password: this.state.password, emptyPassword: false });
    //   }
    // }
    // const errors = this.validate();

    // this.setState({ errors: errors || {} });

    let message = ""

    // if (!errors) {
    try {

      axios.post(RESET_PASSWORD, data_to_send, headerConfig)
        .then(data => {
          if (data.data.status === "success") {
            this.setState({ message: "success", errorMessage: data.data.result, confirmed: true, password: '', ConfirmPassword: '', userName: '', errors: {} })

          } else {
            this.setState({ message: "failed", errorMessage: data.data.result.message, password: '', ConfirmPassword: '', userName: '', errors: {} })

          }
        })
        .catch(ex => {
          if (ex.response && ex.response.status === 400 || ex.response && ex.response.status === 404 ||
            ex.response && ex.response.status === 401 || ex.response && ex.response.status === 500) {
            this.setState({ message: "failed", errorMessage: ex.response.data.result.message })
          }

        });

    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors, loadingButton: false });
      }
    }
    // }

    /* this.props.password(formProps, () => {
      this.props.history.push("/dashboard");
    });*/
  };
  render() {
    const { t } = this.props
    const { handleSubmit } = this.props;
    const { errors, message, errorMessage } = this.state;

    return (
      <div>

        <div className="login-page">
          <div className="row justify-content-md-center">
            <div className="col-md-4 col-sm-8">
              <a href="/">
                <img
                  src={logo}
                  width="150px"
                  alt="Logo"
                  className="user-avatar"
                />
              </a>
              <h1>{t("Reset Password")}</h1>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="form-content reset-pwd">

                  {message == "success" && (
                    <div className="form-group invalid-msg">
                      <p>{errorMessage}<a className="login-butt" href='/'>{t("Log In")}</a></p>
                    </div>
                  )}

                  {message == "failed" && (
                    <div className="form-group invalid-msg">
                      <p>{errorMessage}</p>
                    </div>
                  )}

                  <div className="form-group">
                    <Field

                      className={
                        errors["userName"]
                          ? "is-invalid form-control input-underline input-lg"
                          : "form-control input-underline input-lg"
                      }
                      placeholder="Username*"
                      name="userName"
                      type="text"
                      component="input"
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      Please enter a valid username
                  </div>
                  </div>
                  <div className="form-group">
                    <Field
                      className={
                        errors["password"]
                          ? "is-invalid form-control input-underline input-lg"
                          : "form-control input-underline input-lg"
                      }
                      placeholder="Password*"
                      name="password"
                      type="password"
                      component="input"
                      autoComplete="new-password"
                      onChange={this.handleChange}
                    />
                    {!this.state.emptyPassword && (<div className="invalid-feedback">
                      Please enter a new password
                    </div>)}
                    {this.state.emptyPassword && (<div className="invalid-feedback">
                      Passwords must have at least 8 characters and contain the following: uppercase letters, lowercase letters, numbers, and symbols.
                    </div>)}
                  </div>

                  <div className="form-group">
                    <Field
                      className={
                        errors["ConfirmPassword"]
                          ? "is-invalid form-control input-underline input-lg"
                          : "form-control input-underline input-lg"
                      }
                      placeholder="Confirm Password*"
                      name="ConfirmPassword"
                      type="password"
                      component="input"
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      Passwords do not match
                    </div>
                  </div>

                </div>
                {message != "success" && (<button className="btn rounded-btn">{t("Send")}</button>)}
                {message == "success" && (<button disabled={true} className="btn rounded-btn">{t("Send")}</button>)}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.authentication.errorMessage };
}


export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({ form: "password" })
)(withTranslation("translations")(ResetPassword));