import React, { Component, Suspense, lazy } from "react";
import { connect } from 'react-redux';
import { Router, Route, Switch } from "react-router-dom";
import SignIn from "./Authentication/SignIn";
import Forget from "./Authentication/Forget";
import ResetPassword from "./Authentication/ResetPassword";

// Higher order function for checking authentication: https://medium.com/@leonardobrunolima/react-tips-higher-order-components-ac9c758050bf
import RequireAuth from "./Authentication/RequireAuth";

// The Main path name of the StoryRBT web application
import { VRBT_PATH_NAME } from "./StoryRBT/APIs/index"

// https://stackoverflow.com/questions/45373742/detect-route-change-with-react-router
// We need to detect route changes across all the pages, so that when the user is inside a service and tries to go to dashboard
// We force him back to the service. He can only go to the dashboard if follows the specified rules.
// Create history object
import history from "./UTILs/history"

// Logo Loader
import Loader from "./Components/LogoLoader/Loader"
import MontyLogo from "./Assets/Images/loader.gif"

// StoryRBT routes
const StoryRBTNavigationRouter = lazy(() => import("./StoryRBT/HOCs/NavigationRouter"));

class CombinedRoutes extends Component {

    constructor() {
        super();
        this.state = {
        }
    }

    componentDidMount() {
        this.unlisten = history.listen((location, action) => {
            // let pathObject = { location, action, isFirstRendering: null}
            // this.props.routeChange(pathObject)
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    RouteConstructor(path, component, key, exact) {
        return <Route path={path} exact={exact} component={RequireAuth(component)} key={key} />
    }

    render() {
        const routes = [

            {
                path: "/" + VRBT_PATH_NAME,
                component: StoryRBTNavigationRouter,
                key: "/" + VRBT_PATH_NAME,
            }
        ]
        const finalRoutes = routes.map(route => {
            return this.RouteConstructor(route.path, route.component, route.key, route.exact)
        })
        return (
            // switched from using BrowserRouter to Router because I need to pass history as a prop
            <Router history={history}>
                <Suspense fallback={<Loader logo={MontyLogo}></Loader>}>
                    <Switch>
                        <Route path={`/`} exact component={SignIn} key={`/`} />
                        <Route path={`/forgot-password`} exact component={Forget} key={`/forgot-password`} />
                        <Route path={`/reset-password/`} component={ResetPassword} key={`/reset-password`} />
                        {finalRoutes.map(route => { return route })}
                    </Switch>
                </Suspense>
            </Router>

        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    token: state.authentication ? state.authentication.token : "",
    token_expiry: state.authentication ? state.authentication.expiry : "",
    mainToken: state.authentication ? state.authentication.mainToken : ""
})

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CombinedRoutes);