export const API_URL_IDENTITY = "http://cloudvas-identity.montymobile.com/api/v1"
export const API_URL_IDENTITY_V2 = "http://cloudvas-identity.montymobile.com/api/v2"
export const API_URL_IDENTITY_V3 = "http://cloudvas-identity.montymobile.com/api/v3"

export const API_LOGIN = API_URL_IDENTITY + "/Auth/AccessToken";
export const API_ENTRY = "http://cloudvas-identity.montymobile.com/api/v1/Auth/Entry"; // this entry takes a username and password. on success it returns entry token.
export const API_ENTRY_V2 = "http://cloudvas-identity.montymobile.com/api/v2/Auth/Entry"; // this entry takes access token. on success it returns an entry token.
export const API_LOGIN_2 = "http://cloudvas-identity.montymobile.com/api/v3/Auth/AccessToken";
export const API_LOGIN_TEMP = "http://cloudvas-identity.montymobile.com/api/v3/Auth/AccessToken";
export const REFRESH_TOKEN = API_URL_IDENTITY_V2 + "/Auth/Refresh"

export const CONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    Authorization: "bearer ",
    "Content-Type": "application/json"
  }
}

// export const API_URL_IDENTITY = "http://34.253.54.11:9000/api/v1"
// export const API_URL_IDENTITY_V2 = "http://34.253.54.11:9000/api/v2"
// export const API_URL_IDENTITY_V3 = "http://34.253.54.11:9000/api/v3"

// export const API_LOGIN = API_URL_IDENTITY + "/Auth/AccessToken";
// export const API_ENTRY = "http://34.253.54.11:9000/api/v1/Auth/Entry"; // this entry takes a username and password. on success it returns entry token.
// export const API_ENTRY_V2 = "http://34.253.54.11:9000/api/v2/Auth/Entry"; // this entry takes access token. on success it returns an entry token.
// export const API_LOGIN_2 = "http://34.253.54.11:9000/api/v3/Auth/AccessToken";
// export const API_LOGIN_TEMP = "http://34.253.54.11:9000/api/v3/Auth/AccessToken";
// export const REFRESH_TOKEN = API_URL_IDENTITY_V2 + "/Auth/Refresh"

export const RESET_PASSWORD = API_URL_IDENTITY + "/Account/ResetPassword";
export const FORGET_PASSWORD = API_URL_IDENTITY_V3 + "/Account/ForgetPassword";

export const CHANGE_PASSWORD = API_URL_IDENTITY_V2 + "/Account/ChangePassword";
export const CREATE_ACCOUNT = API_URL_IDENTITY_V3 + "/Users/Register";

export const LOGINCONFIG = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Content-Type": "application/json",
    "Tenant-Key": "7760f3f6-1062-488b-9a82-645f03c65e44",
  }
}

export const CONFIGNOAHTH = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Content-Type": "application/json"
  }
};