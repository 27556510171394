import {refresher_Initialization} from "../Actions/index"

let hasRehydrated = false;

export const onRehydrationMiddleware = store => next => action => {
    if (!hasRehydrated && action.type === 'persist/REHYDRATE') {
        hasRehydrated = true;
        next(action)
        store.dispatch(refresher_Initialization());
        // var err = new Error();
        // console.log("err.stack",err.stack);
        // console.trace()
    } else {
        next(action);
    }
};