const initialState = {
    lang: "en",
    dir: "ltr"
}

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LANGUAGE_CHANGE':
            return {
                ...state,
                lang: action.lang
            }
        case 'DIRECTION_CHANGE':
            return {
                ...state,
                dir: action.dir
            }
        default:
            return state
    }
}

export default languageReducer