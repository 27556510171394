import axios from 'axios';


// this function searches an object for a key, if the key exists, it takes the "key-value" pair and store it in searchResult array
// for example if you are looking for the key "userName" in {users: { user1: {userName: Noor}, user2: {userName: Sam}}}
// searchResult will have inside it [{userName: Noor}, {userName: Sam}]
function customFilter(object, searchResult , propertyKey){
    if(object.hasOwnProperty(propertyKey)){
        searchResult.push(object);
    }

    for(var i=0; i<Object.keys(object).length; i++){
        // null is considered an object, this creates trouble because when the if statement see null, it will fire a call to customFilter with an object parameter as null
        // when customFilter starts excuting it will try to evaluate object.hasOwnProperty(propertyKey), this will give an error because the object itself is null
        // so trying to access the hasOwnProperty will explode
        if(typeof object[Object.keys(object)[i]] == "object" && object[Object.keys(object)[i]] !== null){
            customFilter(object[Object.keys(object)[i]], searchResult, propertyKey);
        }
    }
}

class HTTP {
    get(url, CONFIG, token, variableSeeked){
        return new Promise((resolve, reject) => {
            CONFIG.headers.Authorization = "bearer " + token;
            axios.get(url, CONFIG).then(response =>{
                // if the user specifies what is the key he is looking for in the object then we go inside the if, where we will call
                // customFilter function to look for the key in the object
                if(variableSeeked && variableSeeked!=="default"){
                    let filteredResponse = [];
                    customFilter(response, filteredResponse, variableSeeked)
                    if(filteredResponse.length===0){
                        // this was reject() but I had to change it to resolve because if you try to make a Promise.all then if a single request explodes
                        // then a reject() will be called and this will make the Promise.all go into the catch() block. This is a behavior I don't want 
                        // for further details check the file Campaigns in the directory /Containers/Campaign/Campaings.js , in particular the function getCampaigns()
                        resolve(new Error("Can't find the specified key in the object"));
                    } else if (filteredResponse.length===1){
                        resolve(filteredResponse[0])
                    } else {
                        resolve(filteredResponse)
                    }
                } else if (variableSeeked && variableSeeked==="default"){
                    // if the user passes "default" as the variableSeeked value, then we don't make a call to customFilter function because 
                    // he wants the response as it is
                    resolve(response)
                } else {
                    let filteredResponse = [];
                    customFilter(response, filteredResponse, "result")
                    if(filteredResponse.length===0){
                        // this was reject() but I had to change it to resolve because if you try to make a Promise.all then if a single request explodes
                        // then a reject() will be called and this will make the Promise.all go into the catch() block. This is a behavior I don't want 
                        // for further details check the file Campaigns in the directory /Containers/Campaign/Campaings.js , in particular the function getCampaigns()
                        resolve(new Error("Can't find the specified key in the object"));
                    } else if (filteredResponse.length===1){
                        resolve(filteredResponse[0])
                    } else {
                        resolve(filteredResponse)
                    }
                }

            }).catch(error => {
                console.error("Error in processing the request", error);
                // this was reject() but I had to change it to resolve because if you try to make a Promise.all then if a single request explodes
                // then a reject() will be called and this will make the Promise.all go into the catch() block. This is a behavior I don't want 
                // for further details check the file Campaigns in the directory /Containers/Campaign/Campaings.js , in particular the function getCampaigns()
                resolve(error);
            })
        })
    }

    post(url, payload, CONFIG, token) {
        CONFIG.headers.Authorization = "bearer " + token;
        return new Promise((resolve, reject) => {
            axios.post(url, payload, CONFIG).then(response => {
                resolve(response);
            }).catch(error =>{
                console.warn("Error was caught in POST request", error);
                reject(error);
            })
        })
    }

    put(url, payload, CONFIG, token) {
        CONFIG.headers.Authorization = "bearer " + token;
        return new Promise((resolve, reject) => {
            axios.put(url, payload, CONFIG).then(response => {
                resolve(response);
            }).catch(error =>{
                console.warn("Error was caught in PUT request", error);
                reject(error);
            })
        })
    }

    delete(url, CONFIG, token) {
        CONFIG.headers.Authorization = "bearer " + token;
        return new Promise((resolve, reject) => {
            axios.delete(url, CONFIG).then(response => {
                resolve(response);
            }).catch(error =>{
                console.warn("Error was caught in DELETE request", error);
                reject(error);
            })
        })
    }
}

export default HTTP