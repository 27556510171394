import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import authentication from "./authentication";
import language from "./language";
import access_level from "./access_level";
import router_locations from "./router_locations";
import { reducer as formReducer } from 'redux-form'


const rootReducer = (history) => combineReducers({
    router: connectRouter(history), // adding connected-react router to our store, so that we can monitor routes changes
    authentication,
    language,
    access_level,
    router_locations,
    form: formReducer
})

export default rootReducer